<template>
    <div>
        <b-card no-body>
            <b-row class="m-2">
                <b-col
                    v-for="stage in stages"
                    :key="stage.label"
                    class="m-0 p-0 stage-container"
                >
                    <b-button
                        class="w-100 h-100 p-0 pt-1 pb-1 stageLink"
                        :to="{ name: 'ca-applications', params: { stage: stage.short } }"
                        v-bind:class="[matchStage&& matchStage.toUpperCase() == stage.short.toUpperCase() ? 'active-stage' : '',]"
                        variant="outline-secondary"
                    >
                        <div class="count mb-50">{{ stage.count }}</div>
                        <div class="stage-avatar">{{ stage.label.toLowerCase()[0] }}</div>
                        <div class="stage-label">{{ stage.label }}</div>
                    </b-button>
                </b-col>
            </b-row>
            <div class="m-2">
                <b-row>
                    <b-col cols="12" v-if="filtered.length == 0">
                        <div class="text-center pb-2">
                            <h3>{{i18nT(`Your job applications will be listed here`)}}</h3>
                            <img :src='placeholderSrc' alt='Error page' class='img-fluid' width='300'>
                            <br/><br/>
                            <h4>{{i18nT(`No matching records found yet`)}}</h4>
                        </div>
                    </b-col>
                    <b-col
                        cols="4"
                        v-for="application in filtered"
                        :key="application.application.Id"
                    >
                        <b-card
                            v-if="application.job_offer"
                            style="border: 1px solid rgba(0, 0, 0, 0.07)"
                        >
                            <b-card-body class="p-0">
                                <b-media style="margin-top: 8px">
                                    <b-media-aside
                                        class="mr-50"
                                        v-if=" application.application.onStartingFrom && application.application.onEnding"
                                    >
                                        <b-avatar size="38" />
                                        <span class="ml-2 pt-0"> {{ `${application.application.onStartingFrom} ~ ${application.application.onEnding}` }}</span>
                                    </b-media-aside>
                                    <b-media-body class="mt-2">
                                        <div>
                                            <h5 class="mb-2">{{ application.job_offer.CategoryTitle}}</h5>
                                            <b-link :to="{name: 'ca-application', params: { id: application.application.Id }}">
                                                <h3>{{ application.job_offer.Label }}</h3>
                                            </b-link>
                                            <div class="match-container">
                                                <b-link
                                                    class="font-weight-bold"
                                                    target="_blank"
                                                    :to="{ name: 'company', params: { id: application.job_offer.WorkspaceId } }"
                                                >
                                                    {{ getWorkspaceName(application.job_offer.WorkspaceId) }}
                                                </b-link>
                                                <span class="match">MATCH {{ application.offer_match }}%</span>
                                            </div>
                                            <div>
                                                <p v-html="truncate(application.job_offer.Description, 50)"></p>
                                            </div>
                                            <div>
                                                {{ i18nT(`Full-time`) }}
                                                <feather-icon icon="HomeIcon" />
                                                {{ i18nT(`Remote work`) }}:
                                                {{
                                                    application.job_offer.Remote === 1
                                                        ? "Allowed"
                                                        : "Not Allowed"
                                                }}
                                            </div>
                                            <div>
                                                {{ i18nT(`Located in`) }}
                                                <feather-icon icon="MapPinIcon" />
                                                {{ application.job_offer.Address }}
                                                {{ application.job_offer.City }}
                                                {{ countries[application.job_offer.Country] }}
                                            </div>
                                            <div>
                                                {{ i18nT(`Salary`) }}
                                                {{ application.job_offer.SalaryFrom }}{{ application.job_offer.Currency }} ~
                                                {{ application.job_offer.SalaryTo }}{{ application.job_offer.Currency }}
                                            </div>
                                            <b-dropdown
                                                variant="link"
                                                toggle-class="p-0"
                                                no-caret
                                                class="menu-more"
                                            >
                                                <template #button-content>
                                                    <feather-icon icon="MoreVerticalIcon" />
                                                </template>
                                                <b-dropdown-item :to="{ name: 'ca-application',params: { id: application.job_offer.Id }}">
                                                  <span class="align-middle ml-50">{{
                                                          i18nT(`View`)
                                                      }}</span>
                                                </b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </b-media-body>
                                </b-media>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardBody,
    BLink,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAuth from '@/auth/useAuth'
import { WEB_BASE_URL } from '@core/utils/constants'
import router from '@/router'

export default {
    components: {
        BRow,
        BCol,
        BButton,
        BDropdown,
        BDropdownItem,
        BCard,
        BLink,
        BAvatar,
        BCardBody,
        BMedia,
        BMediaAside,
        BMediaBody,
    },
    directives: {
        Ripple
    },
    data() {
        return {
            stages: [],
            filtered: [],
            countries: {},
            WEB_BASE_URL
        }
    },
    computed: {
        matchStage() {
            return router.currentRoute.params.stage
        },

        placeholderSrc() {
            return require('@/assets/images/pages/error.svg')
        }
    },
    created() {
        this.$http.get(`candidates/stages`).then(({ data }) => {
            //this.stages = data.data.stages.filter(stage => stage.count !== 0)
            this.stages = data.data.stages;
        })

        this.workspaces = useAuth.getWorkspaces()

        this.$http.get(`system/countries`).then(({ data }) => {
            this.countries = data.data
        })

        this.$http
            .get(`candidates/applications?filters[stage]=${this.matchStage}`)
            .then(({ data }) => {
                //console.log(data.data.records)
                this.filtered = data.data.records
            })
    },
    methods: {
        getWorkspaceName(workspaceId) {
            const workspace = this.workspaces.find(space => space.Id === workspaceId)
            if (workspace) {
                return workspace.Label
            }

            return ''
        },
        truncate: function(data, num) {
            const reqdString = data
                .split(' ')
                .slice(0, num)
                .join(' ')
            return data.length > reqdString.length ? reqdString + '...' : reqdString
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
.stage-container {
    background: white;
    border: 1px solid $primary;
    border-right: 0px;
    overflow: hidden;

    button {
        border: none !important;
        border-radius: 0px;
    }
    button:focus,
    button:active {
        background-color: $primary !important;
    }

    .count {
        font-size: 32px;
        color: #5e5873;
        font-weight: 300;
    }

    .stage-label {
        color: #5e5873;
        font-size: 10px;
    }

    .stage-avatar {
        color: white;
        width: 14px;
        height: 14px;
        background: #5e5873;
        display: inline-block;
        margin-right: 4px;
        padding-top: 1px;
        font-size: 10px;
    }
}

.stage-container:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.stage-container:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: solid 1px gray;
}

.active-stage {
    background-color: $primary !important;
    span {
        color: white !important;
    }
}

.cv-card {
    min-height: 180px;
    span {
        font-size: 12px;
    }
}

.menu-more {
    position: absolute;
    right: 12px;
    top: 12px;
}

.match {
    display: inline-block;
    background: red;
    color: white;
    font-size: 8px;
    padding: 4px;
    border-radius: 8px;
    font-weight: bold;
    position: absolute;
    right: 8px;
}

.match-container {
    padding-top: 4px;
    padding-bottom: 4px;
    position: relative;
}

.stageLink {
    border: none !important;
    border-radius: 0px;
}
.stageLink:focus,
.stageLink:active {
    background-color: $primary !important;
}
</style>
